<template>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Servicios / Listado de Servicio</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Detalle de Servicio</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12 border-2">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h4>
        <strong>DETALLE DE SERVICIO Nº {{ venta.id }} - </strong>
        <span
          v-if="venta.tipo_pago_nombre == 'PAGO AL CONTADO'"
          style="color: #28a745"
        >
          {{ venta.tipo_pago_nombre }}
        </span>
        <span
          v-else-if="venta.tipo_pago_nombre == 'PAGO AL CRÉDITO'"
          style="color: #dc3545"
        >
          {{ venta.tipo_pago_nombre }}
        </span>
      </h4>
    </div>
    <div class="grid style_card" style="background-color: #f8f9fa">
      <div class="col-4">
        <p class="mb-0">
          <strong>CLIENTE: </strong>
          <span>{{ venta.razon_social }}</span>
        </p>
        <p class="mb-0">
          <strong>NIT/CI: </strong> <span>{{ venta.nit }}</span>
        </p>
      </div>
      <div class="col-4">
        <p class="mb-0">
          <strong>DIFUNTO: </strong> <span>{{ venta.nombre_difunto }}</span>
        </p>
        <p class="mb-0">
          <strong>FECHA CREMACI&Oacute;N: </strong>
          <span> {{ venta.fecha_cremacion }}</span>
        </p>
        <p class="mb-0">
          <strong>ESTADO: </strong>
          <span>{{ venta.estado_texto }}</span>
        </p>
      </div>
      <div class="col-4">
        <p class="mb-0">
          <strong>Nº. FACTURA: </strong>
          <span>{{ venta.nrofactura }}</span>
        </p>
        <p class="mb-0">
          <strong>FECHA: </strong>
          <span>{{ venta.fecha_venta }}</span>
        </p>
        <p class="mb-0">
          <strong>VENDEDOR: </strong>
          <span>{{ venta.nombre_usuario_caja }}</span>
        </p>
      </div>
    </div>
    <div class="grid mt-4">
      <DataTable
        ref="ventas"
        :value="productos"
        class="col-12 p-datatable-sm"
        showGridlines
        style="
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          line-height: 1.5;
        "
      >
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>
        <Column field="codigo_interno" header="COD. INTERNO">
          {{ producto.codigo_interno }}
        </Column>
        <Column
          field="pivot.cantidad"
          header="CANTIDAD"
          style="text-align: right"
        >
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.pivot.cantidad) }}
          </template>
        </Column>
        <Column
          field="descripcion"
          header="DESCRIPCION"
          style="font-weight: bold"
        >
          {{ producto.descripcion }}
        </Column>
        <Column
          field="pivot.precio"
          header="PRECIO UNITARIO"
          style="text-align: right"
        >
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.pivot.precio) }}
          </template>
        </Column>
        <Column
          field="pivot.descuento_neto"
          header="DESCUENTO"
          style="text-align: right"
        >
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(
                slotProps.data.pivot.descuento_neto ?? 0
              )
            }}
          </template>
        </Column>
        <!--  <Column field="pivot.descuento_neto" header="DESCUENTO">
          <template #body="slotProps">
            {{ convertirNumeroGermanicFormat(slotProps.data.pivot.descuento_neto) }}
          </template>
        </Column> -->
        <Column header="TOTAL" style="text-align: right">
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(
                slotProps.data.pivot.cantidad * slotProps.data.pivot.precio -
                  slotProps.data.pivot.descuento_neto
              )
            }}
          </template>
        </Column>
      </DataTable>
      <table
        class="table table-bordered table-striped"
        width="100%"
        cellspacing="0"
        style="font-size: 1.3rem"
      >
        <tbody class="text-right">
          <tr>
            <td class="text-right">
              <strong>SUBTOTAL Bs: </strong>
              {{ convertirNumeroGermanicFormat(subtotal) }}
            </td>
          </tr>
          <tr>
            <td class="text-right">
              <strong>DESCUENTO Bs: </strong>
              <span class="text-green-500 font-bold">
                {{ convertirNumeroGermanicFormat(venta.descuento) }}
              </span>
            </td>
          </tr>
          <tr v-if="venta.tipo_pago_id == 2">
            <td>
              <strong>A CUENTA Bs:</strong>
              {{ convertirNumeroGermanicFormat(venta.total_pagos) }}
            </td>
          </tr>
          <tr>
            <td>
              <strong>
                MONTO
                {{ venta.tipo_pago_id == 1 ? "CANCELAR" : "DEBE" }} Bs:
              </strong>
              {{
                convertirNumeroGermanicFormat(
                  venta.tipo_pago_id == 1
                    ? venta.total_cancelar
                    : venta.total_cancelar - venta.total_pagos
                )
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="grid mt-2 justify-content-end">
      <Button
        @click="goBack"
        class="p-button-primary p-button-lg"
        v-tooltip.top="'Volver a Ventas'"
      >
        <i class="pi pi-arrow-left"></i>&nbsp;ATR&Aacute;S
      </Button>
      <Button
        v-if="venta.facturado == 1"
        label="IMPRIMIR TICKET"
        icon="pi pi-print"
        class="p-button-lg p-button-success ml-2"
        v-tooltip.top="'Imprimir Ticket'"
        @click="imprimirVenta2(venta.id)"
        :loading="imprimiendoTiket"
      >
      </Button>
      <Button
        :label="
          venta.facturado == 1
            ? 'IMPRIMIR FACTURA'
            : 'IMPRIMIR ORDEN DE SERVICIO'
        "
        icon="pi pi-print"
        class="p-button-lg p-button-danger ml-2"
        v-tooltip.top="'Imprimir PDF'"
        @click="imprimirVenta(venta.id)"
        :disabled="imprimiendo"
        :loading="imprimiendo"
      >
      </Button>
      <!--<Button
        label="ENVIAR WHATSAPP"
        icon="pi pi-whatsapp"
        class="p-button-lg p-button-success ml-2"
        v-tooltip.top="'Enviar por WhatsApp'"
        @click="enviarWhatsApp(venta)"
        :loading="enviando_whatsapp"
      >
      </Button>-->
    </div>
  </div>
</template>

<script>
import VentaService from "@/service/VentaService";
export default {
  data() {
    return {
      venta: {},
      venta_id: this.$route.params.id,
      productos: [],
      imprimiendo: false,
      imprimiendoTiket: false,
      enviando_whatsapp: false,
    };
  },
  ventaService: null,
  created() {
    this.ventaService = new VentaService();
  },
  mounted() {
    this.cargarVenta();
  },
  computed: {
    subtotal() {
      let subtotal = 0;
      this.productos.forEach((producto) => {
        subtotal += producto.pivot.precio * producto.pivot.cantidad;
      });
      return subtotal;
    },
  },
  methods: {
    enviarWhatsApp(datos) {
      this.enviando_whatsapp = true;
      this.ventaService
        .enviarWhatsApp(datos)
        .then((datos) => {
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: datos.mensaje,
            life: 3000,
          });
          this.enviando_whatsapp = false;
        })
        .catch((error) => console.log(error));
    },
    imprimirVenta(id) {
      if (this.imprimiendo) {
        return;
      }
      this.imprimiendo = true;
      this.ventaService.imprimirVenta(id).then(() => {
        this.imprimiendo = false;
      });
    },
    imprimirVenta2(id) {
      if (this.imprimiendo) {
        return;
      }
      this.imprimiendoTiket = true;
      this.ventaService.imprimirVenta2(id).then(() => {
        this.imprimiendoTiket = false;
      });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    cargarVenta() {
      this.ventaService.getVenta(this.venta_id).then((response) => {
        this.venta = response.venta;
        this.productos = this.venta.productos;
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}
.style_card {
  border: 1px solid #000000;
  border-bottom: 3px solid #000000;
  padding: 0px;
  background-color: #f8f9fa;
  border-radius: 5px;
}
</style>
